import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setFilter } from './../../other/actions/filter';

// Import helpers
import { validateFields } from './../../other/helpers/validate-fields';

// Import components
import Loader from './../loader/loader';
import Input from './../input/input';
import Select from './../select/select';
import Popup from './../popup/popup';

// Import styles
import './filter-item.scss';

interface props {
	'filter': {
		[key: string]: any
	},
	'isSmall': boolean,
	'pageId': string,
	'showFilterPopup': boolean,
	'onShowFilterPopup': any,
	'onFilterChange': any
};

declare const window: any;

const FilterItem = (props: props) => {
	const dispatch = useDispatch();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initFilterQuery: {[key: string]: any} = {
		'type': '',
		'category': '',
		'country': '',
		'city': '',
		'interest': '',
		'postKind': '',
		'searchPhrase': '',
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false);
	const [filterQuery, setFilterQuery] = useState<{[key: string]: any}>(initFilterQuery);
	const [errors, setErrors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		setFilterQuery({
			'postKind': props.filter.postKind || initFilterQuery.postKind,
			'country': props.filter.country || initFilterQuery.country,
			'city': props.filter.city || initFilterQuery.city,
			'type': props.filter.type || initFilterQuery.type,
			'category': props.filter.category || initFilterQuery.category,
			'interest': props.filter.interest || initFilterQuery.interest,
			'searchPhrase': props.filter.searchPhrase || initFilterQuery.searchPhrase,
		});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.filter]);

	const onSubmitForm = async (e: any, resetFilter: boolean, postKind: string) => {
		if (e) { e.preventDefault(); }

		if (!auth.isAuthenticated) {
			setNotifications(generalText.messages?.userNotAuthorizedError);
			return;
		}

		setIsLoading(true);
		setErrors({});

		let filterData: {[key: string]: any} = {
		}

		// let filterData: {[key: string]: any} = {
		// 	'postKind': postKind || filterQuery.postKind
		// };

		// if (postKind === filterQuery.postKind) {
		// 	filterData['postKind'] = initFilterQuery.postKind
		// }

		// Set filter data
		if (!resetFilter) {
			if (filterQuery.type !== '') {
				filterData['type'] = filterQuery.type;
			}

			if (filterQuery.category !== '') {
				filterData['category'] = filterQuery.category;
			}


			if (filterQuery.country !== '') {
				filterData['country'] = filterQuery.country;
			}

			if (filterQuery.city !== '') {
				filterData['city'] = filterQuery.city;
			}

			if (filterQuery.interest !== '') {
				filterData['interest'] = filterQuery.interest;
			}

			if (filterQuery.postKind !== '') {
				filterData['postKind'] = filterQuery.postKind;
			}

			if (filterQuery.searchPhrase !== '') {
				filterData['searchPhrase'] = filterQuery.searchPhrase;
			}

			let fieldErrors = validateFields(filterData, ['filter']);

			// Validation
			if (!_.isEmpty(fieldErrors)) {
				setIsLoading(false);
				setErrors(fieldErrors);

				return;
			}
		}

		await new Promise((resolve, reject) => {
			api.post('/add-filter', filterData).then((res) => {
				resolve(true);

			}).catch((err) => {
				setIsLoading(false);
				props.onShowFilterPopup(false);
				setNotifications(err.response?.data?.messages);
				reject(false);
			});
		});

		api.get('/get-filter').then((res) => {
			setIsLoading(false);
			props.onShowFilterPopup(false);
			dispatch(setFilter(res.data));
			props.onFilterChange(res.data);

			// Facebook event Search
			if (window.fbq) {
				window.fbq('track', 'Search', {
					'event_time': Math.floor(Date.now() / 1000),
					'event_name': 'Search',
					'event_source_url': window.location.href,
					'action_source': 'website'
				});
			}
		}).catch((err) => {
			props.onShowFilterPopup(false);
			setNotifications(err.response?.data?.messages);
		});
	}

	const isExpandedFilterEmpty: boolean = 
		!props.filter.city && 
		!props.filter.type && 
		!props.filter.searchPhrase;

	return (
		<>
			<Loader active={isLoading} fixed={true} />

			<Popup
				title={generalText.filter?.popup1?.title}
				active={props.showFilterPopup}
				onClose={() => props.onShowFilterPopup(false)}
			>
				<>
					<div className="SECTION no-bottom-padding">
						<div className="grid">
							<div className="row">
								{
									!auth.isAuthenticated &&
									generalText.filter?.popup1?.text1 &&
									<div className="col-12" dangerouslySetInnerHTML={{ __html: generalText.filter?.popup1?.text1 }}></div>
								}

								<div className="col-6">
									<div className="FIELD">
										<Select
											value={filterQuery.type}
											label={generalText.filter?.popup1?.input3?.label}
											placeholder={generalText.filter?.popup1?.input3?.placeholder}
											icon={generalText.filter?.popup1?.input3?.icon}
											nullable={true}
											strict={true}
											options={[]}
											dbOptions={'types'}
											errors={errors.type}
											onChange={(val: string) => setFilterQuery(prevState => ({...prevState, 'type': val}))}
										/>
									</div>
								</div>

								<div className="col-6">
									<div className="FIELD">
										<Select
											value={filterQuery.category}
											label={generalText.filter?.popup1?.input4?.label}
											placeholder={generalText.filter?.popup1?.input4?.placeholder}
											icon={generalText.filter?.popup1?.input4?.icon}
											nullable={true}
											strict={true}
											options={[]}
											dbOptions={'categories'}
											errors={errors.category}
											onChange={(val: string) => setFilterQuery(prevState => ({...prevState, 'category': val}))}
										/>
									</div>
								</div>

								<div className="col-12">
									<div className="FIELD">
										<Select
											value={filterQuery.country}
											label={generalText.filter?.popup1?.input1?.label}
											placeholder={generalText.filter?.popup1?.input1?.placeholder}
											icon={generalText.filter?.popup1?.input1?.icon}
											nullable={true}
											strict={false}
											options={[]}
											dbOptions={'countries'}
											errors={errors.country}
											onChange={(val: string) => setFilterQuery(prevState => ({...prevState, 'country': val}))}
										/>
									</div>
								</div>

								{
									(
										!isFilterExpanded &&
										isExpandedFilterEmpty
									) &&
									<div className="col-12">
										<p><span className="LINK" onClick={() => setIsFilterExpanded(true)}>Use detailed filter?</span></p>
									</div>
								}

								{
									(
										isFilterExpanded ||
										!isExpandedFilterEmpty
									) &&
									<div className="col-12">
										<div className="FIELD">
											<Select
												value={filterQuery.city}
												label={generalText.filter?.popup1?.input2?.label}
												placeholder={generalText.filter?.popup1?.input2?.placeholder}
												icon={generalText.filter?.popup1?.input2?.icon}
												nullable={true}
												strict={false}
												options={[]}
												dbOptions={'cities'}
												errors={errors.city}
												onChange={(val: string) => setFilterQuery(prevState => ({...prevState, 'city': val}))}
											/>
										</div>
									</div>
								}

								{
									(
										isFilterExpanded ||
										!isExpandedFilterEmpty
									) &&
									<div className="col-6">
										<div className="FIELD">
											<Select
												value={filterQuery.interest}
												label={generalText.filter?.popup1?.input5?.label}
												placeholder={generalText.filter?.popup1?.input5?.placeholder}
												icon={generalText.filter?.popup1?.input5?.icon}
												nullable={true}
												strict={true}
												options={[]}
												dbOptions={'interests'}
												errors={errors.interest}
												onChange={(val: string) => setFilterQuery(prevState => ({...prevState, 'interest': val}))}
											/>
										</div>
									</div>
								}

								{
									(
										isFilterExpanded ||
										!isExpandedFilterEmpty
									) &&
									<div className="col-6">
										<div className="FIELD">
											<Select
												value={filterQuery.postKind}
												label={generalText.filter?.popup1?.input7?.label}
												placeholder={generalText.filter?.popup1?.input7?.placeholder}
												icon={generalText.filter?.popup1?.input7?.icon}
												nullable={true}
												strict={true}
												options={[]}
												dbOptions={'post-kinds'}
												errors={errors.postKind}
												onChange={(val: string) => setFilterQuery(prevState => ({...prevState, 'postKind': val}))}
											/>
										</div>
									</div>
								}

								{
									(
										isFilterExpanded ||
										!isExpandedFilterEmpty
									) &&
									<div className="col-12">
										<div className="FIELD">
											<Input
												value={filterQuery.searchPhrase}
												label={generalText.filter?.popup1?.input6?.label}
												placeholder={generalText.filter?.popup1?.input6?.placeholder}
												icon={generalText.filter?.popup1?.input6?.icon}
												type="text"
												errors={errors.searchPhrase}
												onChange={(val: string) => setFilterQuery(prevState => ({...prevState, 'searchPhrase': val}))}
											/>
										</div>
									</div>
								}
							</div>
						</div>
					</div>

					<div className="SECTION no-bottom-padding">
						<div className="grid">
							<div className="row">
								{
									generalText?.filter?.button2?.text &&
									<div className="col-4">
										<span onClick={() => onSubmitForm(false, true, '')} className="BUTTON diff1">
											{ generalText?.filter?.button2?.text }
										</span>
									</div>
								}

								{
									generalText.filter?.popup1?.button1?.text &&
									<div className="col-8">
										<button onClick={() => onSubmitForm(false, false, '')} className="BUTTON">
											{ generalText.filter?.popup1?.button1?.text }
										</button>
									</div>
								}
							</div>
						</div>
					</div>
				</>
			</Popup>

			<Popup
				title={notifications?.title || generalText.generalPopup?.title}
				text={notifications?.text || ['Unhandled error - #1253464534534']}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>
		</>
	);
}

export default FilterItem;