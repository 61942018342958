import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { IPInfoContext } from 'ip-info-react';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { validateFields } from './../../other/helpers/validate-fields';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import configs
import keys from './../../other/configs/keys';

// Import components
import Input from './../input/input';

interface props {
	'pageId': string,
	'notifications': any,
	'isLoading': any,
	'showRemindPasswordPopup': any
};

declare const window: any;

const SignIn = (props: props) => {
	const dispatch = useDispatch();

	const userInfo = useContext(IPInfoContext);

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initUser: {[key: string]: any} = {
		'email': '',
		'password': '',
	}

	const [user, setUser] = useState<{[key: string]: any}>(initUser);
	const [errors, setErrors] = useState<{[key: string]: any}>({});	

	useEffect(() => {
		if (!_.isEmpty(errors)) {
			setErrors({});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const onSubmitForm = async (e: any) => {
		e.preventDefault();

		// If user is already signed in
		if (auth.isAuthenticated) {
			props.notifications({'text': ['You are already signed in. Please refresh the page.']});

			return;
		}

		props.isLoading(true);

		// Validate fields
		let stateClone: any = _.cloneDeep(user);
		
		// Validation
		let fieldErrors = validateFields(stateClone, ['user']);

		if (!_.isEmpty(fieldErrors)) {
			props.isLoading(false);
			setErrors(fieldErrors);

			return;
		}

		if (userInfo.ip !== undefined) {
			stateClone['networkDetails'] = userInfo;
		}

		api.post('/sign-in', stateClone).then((res) => {
			// If user signed in or registered
			if (res.status === 200) {
				setUser(initUser);

				dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));
			}

		}).catch((err) => {
			props.isLoading(false);
			props.notifications(err.response?.data?.messages);

			setUser(prevState => ({...prevState, 'password': initUser.password}));
		});
	}

	return (
		<form onSubmit={(e) => onSubmitForm(e)}>
			<div className="AUTH no-padding">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="SECTION__title">{ generalText.signIn?.title }</div>
						</div>

						<div className="col-12">
							<div className="FIELD">
								<Input
									value={user.email}
									label={generalText.signIn?.input1?.label}
									placeholder={generalText.signIn?.input1?.placeholder}
									icon={generalText.signIn?.input1?.icon}
									type="text"
									errors={errors.email}
									onChange={(val: string) => setUser(prevState => ({...prevState, 'email': val.replace(/ /g,'')}))}
								/>
							</div>
						</div>

						<div className="col-12">
							<div className="FIELD">
								<Input
									value={user.password}
									label={generalText.signIn?.input2?.label}
									placeholder={generalText.signIn?.input2?.placeholder}
									icon={generalText.signIn?.input2?.icon}
									type="password"
									errors={errors.password}
									onChange={(val: string) => setUser(prevState => ({...prevState, 'password': val}))}
								/>
							</div>
						</div>

						{
							generalText.signIn?.forgotPasswordLink?.text &&
							<div className="col-12">
								<div className="AUTH__password">
									<span onClick={() => props.showRemindPasswordPopup(true)} className="LINK">{ generalText.signIn?.forgotPasswordLink?.text}</span>
								</div>
							</div>
						}

						{
							generalText.signIn?.button1?.text &&
							<div className="col-12 col-sm-8 push-sm-2">
								<button type="submit" className="BUTTON">{ generalText.signIn?.button1?.text }</button>
							</div>
						}

						{
							generalText.signIn?.text1 &&
							<div className="col-12 text-center">
								{
									parse(generalText.signIn?.text1, {
										replace: (domNode: any) => {
											if (domNode.attribs?.class === '%repStr1%') {
												return <Link to={slugByPageId('sign-up', lang, routes)} className="LINK">{ domToReact(domNode.children) }</Link>;
											}
										}
									})
								}
							</div>
						}

						{
							generalText.signIn?.text2 &&
							<div className="col-12">
								<div className="AUTH__consent text-center">
									{
										parse(generalText.signIn?.text2, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <Link to={slugByPageId('terms', lang, routes)} className="LINK">{ domToReact(domNode.children) }</Link>;
												}

												if (domNode.attribs?.class === '%repStr2%') {
													return <Link to={slugByPageId('privacy', lang, routes)} className="LINK">{ domToReact(domNode.children) }</Link>;
												}

												if (domNode.attribs?.class === '%repStr3%') {
													return <Link to={slugByPageId('cookies', lang, routes)} className="LINK">{ domToReact(domNode.children) }</Link>;
												}

												if (domNode.attribs?.class === '%repStr4%') {
													return <span onClick={() => {window.open('mailto:' + keys.contactEmail)}} className="LINK">{ domToReact(domNode.children) }</span>;
												}
											}
										})
									}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</form>
	);
}

export default SignIn;