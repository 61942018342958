import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import IPInfo from 'ip-info-react';

// Import middlewares
import api from './other/middlewares/api';

// Import actions
import { setRoutes } from './other/actions/routes';
import { setCurrentUser } from './other/actions/auth';
import { setFilter } from './other/actions/filter';

// Import helpers
import { setCookie, checkCookie } from './other/helpers/cookies';
import { slugByPageId } from './other/helpers/route-finder';

// Import pages
import MainLayout from './main-layout';

import Auth from './pages/auth/auth';
import Main from './pages/main/main';
import ProfilesFeed from './pages/profiles-feed/profiles-feed';
import PostsFeed from './pages/posts-feed/posts-feed';
import About from './pages/about/about';
import Other from './pages/other/other';
import Qr from './pages/qr/qr';
import Conversations from './pages/conversations/conversations';
import Conversation from './pages/conversation/conversation';
import UserProfiles from './pages/user-profiles/user-profiles';
import Favorites from './pages/favorites/favorites';
import Logs from './pages/logs/logs';
import EditProfile from './pages/edit-profile/edit-profile';
import Settings from './pages/settings/settings';
import Profile from './pages/profile/profile';
import Post from './pages/post/post';
import EmailConfirmation from './pages/email-confirmation/email-confirmation';

import CmsLayout from './cms/cms-layout';

import CmsDashboard from './cms/pages/cms-dashboard/cms-dashboard';
import CmsUsers from './cms/pages/cms-users/cms-users';
import CmsProfiles from './cms/pages/cms-profiles/cms-profiles';
import CmsPosts from './cms/pages/cms-posts/cms-posts';
import CmsComments from './cms/pages/cms-comments/cms-comments';
import CmsReports from './cms/pages/cms-reports/cms-reports';
import CmsEmails from './cms/pages/cms-emails/cms-emails';
import CmsTrashedUsers from './cms/pages/cms-trashed-users/cms-trashed-users';
import CmsTrashedProfiles from './cms/pages/cms-trashed-profiles/cms-trashed-profiles';
import CmsTrashedPosts from './cms/pages/cms-trashed-posts/cms-trashed-posts';
import CmsTrashedComments from './cms/pages/cms-trashed-comments/cms-trashed-comments';

import Text from './pages/text/text';
import Response from './pages/response/response';

// Import components
import Popup from './components/popup/popup';
import ChatWidget from './components/chat-widget/chat-widget';

import './assets/styles/style.scss';

declare const window: any;

let tokenExpiry: any;

function App() {
	const dispatch = useDispatch();
	const location = useLocation();

	const nodeRef = useRef(null);

	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const [isRoutesLoaded, setIsRoutesLoaded] = useState<boolean>(false);
	const [isAuthLoaded, setIsAuthLoaded] = useState<boolean>(false);
	const [notifications, setNotifications] = useState<{[key: string]: any}>({'title': '', 'text': []});

	useEffect(() => {
		// window.scrollTo({'top': 0, 'behavior': 'smooth'});
		// const root: any = document.getElementById('root');

		// if (root) {
		// 	root.scrollTo({'top': 0, 'behavior': 'smooth'});
		// }

		// window.ttq?.track('ViewContent', {
		// 	'contents': [{
		// 		'content_name': location.pathname,
		// 	}],
		// });

		if (window.fbq) {
			window.fbq('track', 'PageView');
		}
	}, [location]);

	let getSignedUser: any = useCallback(() => {
		// Try to get user's latest data
		api.get('/get-signed-user').then((res) => {
			dispatch(setCurrentUser({...res.data, 'toggleUpdate': auth.toggleUpdate}));
			setIsAuthLoaded(true);

			if (!tokenExpiry && !_.isEmpty(res.data)) {
				tokenExpiry = setInterval(() => {
					getSignedUser();
				}, 20000);
			}

			if (tokenExpiry && _.isEmpty(res.data)) {
				clearInterval(tokenExpiry);
			}
		
		}).catch((err) => {
			setIsAuthLoaded(true);
			setNotifications(err.response?.data?.messages);
			return;
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getSignedUser();
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.toggleUpdate]);

	useEffect(() => {
		if (!auth.isAuthenticated) {
			dispatch(setFilter({}));
			return;
		}

		// If user signed in or out, get or remove filter settings
		api.get('/get-filter').then((res) => {
			dispatch(setFilter(res.data));
		}).catch((err) => {
			setNotifications(err.response?.data?.messages);
		});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.isAuthenticated]);

	// Check if webp is supported and add cookie
	useEffect(() => {
		if (!checkCookie('siteWebpSupported')) {
			let elem = document.createElement('canvas');

			if (!!(elem.getContext && elem.getContext('2d'))) {
				// Was able or not to get WebP representation
				if (elem.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
					setCookie('siteWebpSupported', 'true', 7);
				}
			} else {
				// Very old browser like IE 8, canvas not supported
				// Don't set a cookie
				// setCookie('siteWebpSupported', 'false', 7);
			}
		}
	}, []);

	useEffect(() => {
		api.get('/get-pages-routes').then((res) => {
			dispatch(setRoutes({'routes': res.data}));
			setIsRoutesLoaded(true);
		}).catch((err) => {
			if (err.message) {
				setNotifications({'title': 'Connection problem', 'text': ['Please check your internet connection and refresh the site. If the problem persists, please come back later.']});
			} else {
				setNotifications(err.response?.data?.messages);
			}
			
			setIsRoutesLoaded(true);
		});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!isRoutesLoaded || !isAuthLoaded) { return null; }

	let isGuestRoute: boolean = !auth.isAuthenticated;
	let isUserRoute: boolean = auth.isAuthenticated;
	let isAdminRoute: boolean = auth.isAuthenticated && auth.adminType >= 1;

	return (
		<IPInfo>
			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #64296234823']}
				closeBtn={generalText?.generalPopup?.button1?.text || 'Close'}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<TransitionGroup component={null}>
				<CSSTransition
					key={location.key}
					classNames="transition"
					timeout={400}
					nodeRef={nodeRef}
				>
					<div className="transition full-height" ref={nodeRef}>
						<Routes location={location}>
							{
								!_.isEmpty(routes) &&
								_.map(routes, (val1, i1) => {
									let langPath = (i1 !== 'en' ? '/' + i1 : '');

									return (
										<Route key={i1} path={langPath + '/*'} element={<MainLayout lang={i1} />}>
											{
												_.map(val1, (val2, i2) => {
													if (!val2.component) { return null; }
													
													switch (val2.component) {
														case 'Main': return <Route key={i2} path={val2.slug} element={isGuestRoute ? <Main pageId={val2.pageId} /> : <Navigate to={slugByPageId('posts-feed', i1, routes)} replace={true} />} />;
														case 'ProfilesFeed': return <Route key={i2} path={val2.slug} element={<ProfilesFeed pageId={val2.pageId} />} />;
														case 'PostsFeed': return <Route key={i2} path={val2.slug} element={<PostsFeed pageId={val2.pageId} />} />;
														case 'About': return <Route key={i2} path={val2.slug} element={<About pageId={val2.pageId} />} />;
														case 'Auth': return <Route key={i2} path={val2.slug} element={isGuestRoute ? <Auth pageId={val2.pageId} /> : <Navigate to={slugByPageId('main', i1, routes)} replace={true} />} />;
														case 'Other': return <Route key={i2} path={val2.slug} element={isUserRoute ? <Other pageId={val2.pageId} /> : <Navigate to={slugByPageId('not-found', i1, routes)} replace={true} />} />;
														case 'Qr': return <Route key={i2} path={val2.slug} element={<Qr pageId={val2.pageId} />} />;
														case 'Conversations': return <Route key={i2} path={val2.slug} element={isUserRoute ? <Conversations pageId={val2.pageId} /> : <Navigate to={slugByPageId('not-found', i1, routes)} replace={true} />} />;
														case 'Conversation': return <Route key={i2} path={val2.slug} element={isUserRoute ? <Conversation pageId={val2.pageId} /> : <Navigate to={slugByPageId('not-found', i1, routes)} replace={true} />} />;
														case 'UserProfiles': return <Route key={i2} path={val2.slug} element={isUserRoute ? <UserProfiles pageId={val2.pageId} /> : <Navigate to={slugByPageId('not-found', i1, routes)} replace={true} />} />;
														case 'Favorites': return <Route key={i2} path={val2.slug} element={isUserRoute ? <Favorites pageId={val2.pageId} /> : <Navigate to={slugByPageId('not-found', i1, routes)} replace={true} />} />;
														case 'Logs': return <Route key={i2} path={val2.slug} element={isUserRoute ? <Logs pageId={val2.pageId} /> : <Navigate to={slugByPageId('not-found', i1, routes)} replace={true} />} />;
														case 'EditProfile': return <Route key={i2} path={val2.slug} element={isUserRoute ? <EditProfile pageId={val2.pageId} /> : <Navigate to={slugByPageId('not-found', i1, routes)} replace={true} />} />;
														case 'Settings': return <Route key={i2} path={val2.slug} element={isUserRoute ? <Settings pageId={val2.pageId} /> : <Navigate to={slugByPageId('sign-in', i1, routes)} replace={true} />} />;
														case 'Profile': return <Route key={i2} path={val2.slug} element={<Profile pageId={val2.pageId} />} />;
														case 'Post': return <Route key={i2} path={val2.slug} element={<Post pageId={val2.pageId} />} />;
														case 'Text': return <Route key={i2} path={val2.slug} element={<Text pageId={val2.pageId} />} />;
														case 'EmailConfirmation': return <Route key={i2} path={val2.slug} element={<EmailConfirmation pageId={val2.pageId} />} />;
														case 'Response': return <Route key={i2} path={val2.slug} element={<Response pageId={val2.pageId} />} />;
													}
												})
											}
											
											<Route path="*" element={<Navigate to="/404" replace={true} />} />
										</Route>
									)
								})
							}

							{
								_.isEmpty(routes) &&
								<Route path="*" element={<Response />} />
							}

							{
								auth.isAuthenticated &&
								<Route path="/cms/*" element={<CmsLayout lang="en" />}>
									<Route path="" element={isAdminRoute ? <CmsDashboard /> : <Navigate to="/404" replace={true} />} />
									
									<Route path="users" element={isAdminRoute ? <CmsUsers /> : <Navigate to="/404" replace={true} />} />
									<Route path="profiles" element={isAdminRoute ? <CmsProfiles /> : <Navigate to="/404" replace={true} />} />
									<Route path="posts" element={isAdminRoute ? <CmsPosts /> : <Navigate to="/404" replace={true} />} />
									<Route path="comments" element={isAdminRoute ? <CmsComments /> : <Navigate to="/404" replace={true} />} />
									<Route path="reports" element={isAdminRoute ? <CmsReports /> : <Navigate to="/404" replace={true} />} />
									<Route path="emails" element={isAdminRoute ? <CmsEmails /> : <Navigate to="/404" replace={true} />} />
									<Route path="trashed-users" element={isAdminRoute ? <CmsTrashedUsers /> : <Navigate to="/404" replace={true} />} />
									<Route path="trashed-profiles" element={isAdminRoute ? <CmsTrashedProfiles /> : <Navigate to="/404" replace={true} />} />
									<Route path="trashed-posts" element={isAdminRoute ? <CmsTrashedPosts /> : <Navigate to="/404" replace={true} />} />
									<Route path="trashed-comments" element={isAdminRoute ? <CmsTrashedComments /> : <Navigate to="/404" replace={true} />} />

									<Route path="*" element={<Navigate to="/404" replace={true} />} />
								</Route>
							}
						</Routes>
					</div>
				</CSSTransition>
			</TransitionGroup>

			<ChatWidget />
		</IPInfo>
	);
}

export default App;
