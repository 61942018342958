import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// Import middlewares
import api from './../../../other/middlewares/api';

// Import helpers
import { checkCookie } from './../../../other/helpers/cookies';
import { slugByPageId } from './../../../other/helpers/route-finder';

// Import configs
import { apiBasePath } from './../../../other/configs/urls';

// Import components
import PageHeader from './../../../components/page-header/page-header';
import Loader from './../../../components/loader/loader';
import Pagination from './../../../components/pagination/pagination';
import Popup from './../../../components/popup/popup';

interface props {};

const CmsTrashedProfiles = (props: props) => {
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const initIds: {[key: string]: any} = {
		'expandedRowId': '',
	}
	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [profiles, setProfiles] = useState<Array<any>>([]);
	const [profilesPage, setProfilesPage] = useState<number>(1);
	const [profilesTotalPages, setProfilesTotalPages] = useState<number>(0);

	const [ids, setIds] = useState<{[key: string]: any}>(initIds);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	const onGetProfiles = useCallback((page: any, searchPhrase: any) => {
		setIsLoading(true);

		if (page === 1) {
			setProfiles([]);
			setProfilesPage(1);
			setProfilesTotalPages(1);
		}

		api.get('/admin-get-trashed-profiles/' + page + (searchPhrase ? ('/' + searchPhrase) : '')).then((res) => {
			setIsLoading(false);
			setProfiles(res.data.entries);
			setProfilesPage(page);

			if (page === 1 && res.data?.total) {
				setProfilesTotalPages(Math.ceil(res.data.total / res.data.entries.length));
			}

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		onGetProfiles(1, '');
	}, [onGetProfiles]);

	return (
		<div className="CMSTRASHEDPROFILES">
			<Loader active={isLoading} fixed={true} />

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #135135154244']}
				closeBtn="Close"
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<PageHeader heading="Profiles" />
			
			<div className="SECTION has-top-border">
				<div className="grid">
					<div className="row">
						<div className="col-12">
							<div className="CMSLAYOUT__table">
								<table>
									<thead>
										<tr>
											<th>
											</th>

											<th>
												<p>Profile ID</p>
											</th>

											<th>
												<p>User</p>
											</th>

											<th>
												<p>Cover</p>
											</th>

											<th>
												<p>Image</p>
											</th>

											<th>
												<p>Profile name &amp; slug</p>
											</th>

											<th>
												<p>Details</p>
											</th>

											<th>
												<p>Interest</p>
											</th>

											<th>
												<p>Skills</p>
											</th>

											<th>
												<p>About</p>
											</th>

											<th>
												<p>Offer</p>
											</th>

											<th>
												<p>Networks</p>
											</th>

											<th>
												<p>Visible</p>
											</th>

											<th>
												<p>Status</p>
											</th>

											<th>
												<p>Status message</p>
											</th>

											<th>
												<p>Created at</p>
											</th>

											<th>
												<p>Updated at</p>
											</th>

											<th>
												<p>Deleted at</p>
											</th>
										</tr>
									</thead>

									<tbody>
										{
											_.map(profiles, (val1, i1) => {
												let coverImage: string = '';

												if (val1.covers?.[0]?.path) {
													coverImage = apiBasePath + '/uploads/' + (checkCookie('siteWebpSupported') ? val1.covers[0].path.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : val1.covers[0].path);
												}

												let profileImage = '';

												if (val1.images?.[0]?.path) {
													profileImage = apiBasePath + '/uploads/' + (checkCookie('siteWebpSupported') ? val1.images[0].path.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : val1.images[0].path);
												}
												
												let bg = '';

												switch (val1.status) {
													case 0: bg = 'yellow'; break;
													case 1: bg = 'green'; break;
													case 2: bg = 'red'; break;
													default: bg = '';
												}

												return (
													<tr key={i1} className={ids.expandedRowId === i1.toString() ? 'expanded': ''}>
														<td>
															<div className="cell">
																<div className={'color bg-' + bg}></div>
																<button onClick={() => setIds(prevState => ({...initIds, 'expandedRowId': (prevState.expandedRowId === i1.toString() ? '' : i1.toString())}))} className="BUTTON size1">{ ids.expandedRowId === i1.toString() ? 'Collapse' : 'Expand' }</button>
															</div>
														</td>

														<td>
															<div className="cell">
																<p><Link to={slugByPageId('profile', lang, routes).replace(':slug', val1.slug)}>{ val1._id }</Link></p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>
																	<strong>User ID:</strong> { val1.userId }<br />
																	<strong>User name:</strong> { val1.user?.firstName } { val1.user?.lastName }<br />
																	<strong>User email:</strong> { val1.user?.email }
																</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<div className="PROFILEIMAGE diff1">
																	<span className="PROFILEIMAGE__inner">
																		<span className="icon-picture-regular"></span>
																		<span className="image" style={{ backgroundImage: 'url(' + coverImage + ')' }}></span>
																	</span>
																</div>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<div className="PROFILEIMAGE diff1">
																	<span className="PROFILEIMAGE__inner">
																		<span className="icon-user-solid"></span>
																		<span className="image" style={{ backgroundImage: 'url('+ profileImage +')' }}></span>
																	</span>
																</div>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>
																	<strong>Name:</strong> { val1.name } <br />
																	<strong>Slug:</strong> { val1.slug }
																</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>
																	<strong>Country:</strong> { val1.country } <br />
																	<strong>City:</strong> { val1.city } <br />
																	<strong>Types:</strong> { val1.types?.join(', ') } <br />
																	<strong>Category:</strong> { val1.category } <br />
																</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<p>{ val1.interests?.join(', ') }</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<p>{ val1.skills?.join(', ') }</p>
															</div>
														</td>

														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ val1.about }</p>
															</div>
														</td>

														<td className="allow-wrap pre-line">
															<div className="cell">
																<p>{ val1.offer }</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																{
																	_.map(val1.networks, (val1, i1) => {
																		let url = val1.url;

																		if (!url.match(/^https?:\/\//i)) {
																			url = 'https://' + url;
																		}
																		
																		return (
																			<p key={i1}><strong>{ val1.network }</strong> - <a href={url} target="_blank" rel="noopener noreferrer">{ val1.url }</a></p>
																		)
																	})
																}
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.isVisible ? 'Yes' : 'No' }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.status }</p>
															</div>
														</td>

														<td className="allow-wrap">
															<div className="cell">
																<p>{ val1.statusMessage }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.createdAt && moment(val1.createdAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.updatedAt && moment(val1.updatedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>

														<td>
															<div className="cell">
																<p>{ val1.deletedAt && moment(val1.deletedAt).format('Do MMMM YYYY [at] HH:mm') }</p>
															</div>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{
						profilesTotalPages > 1 &&
						<div className="row">
							<div className="col-12">
								<Pagination
									currentPage={profilesPage}
									totalPages={profilesTotalPages}
									prevText="Prev"
									nextText="Next"
									onChangePage={(val: number) => onGetProfiles(val, '')}
								/>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default CmsTrashedProfiles;