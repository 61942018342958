import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';
import { setCookie } from './../../other/helpers/cookies';

interface props {
	'pageId'?: string
}

declare const window: any;

const Response = (props: props) => {
	const navigate = useNavigate();

	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	
	const [text, setText] = useState<{[key: string]: any}>({});

	useEffect(() => {
		if (!props.pageId) { return; }

		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	const onAcceptCookies = () => {
		setCookie('siteCookiesAccepted', 'true', 365);

		navigate(slugByPageId('main', lang, routes));
	}

	const onDeclineCookies = () => {
		// Clear localstorage
		localStorage.clear();
		// sessionStorage.clear();

		// // Disable Hotjar
		// if (typeof window.hj === 'function') {
		// 	//@ts-ignore
		// 	hj('disable');
		// }

		// // Revoke Facebook Pixel
		// if (typeof window.fbq === 'function') {
		// 	//@ts-ignore
		// 	fbq('consent', 'revoke');
		// }

		// Select all <script> tags where the src contains relevant keywords
		const scripts = document.querySelectorAll('script[src*="facebook"], script[src*="hotjar"], script[src*="googletagmanager"], script[src*="google-analytics.com"], script[src*="gtag/js"]');
		
		scripts.forEach(script => {
			script.remove();
		});

		// Clear cookies
		const cookies = document.cookie.split(';');

		cookies.forEach(cookie => {
			const cookieName = cookie.split('=')[0].trim();
			
			// To ensure cookies with different paths or domains are also cleared
			const cookieDomain = window.location.hostname;
			const paths = [
				'/'
			];

			paths.forEach(path => {
				document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=" + path + "; domain=" + cookieDomain;
			});
		});

		setCookie('siteCookiesDeclined', 'true', 7);

		// navigate(slugByPageId('main', lang, routes));
		window.location.replace(slugByPageId('main', lang, routes));
	}

	return (
		<div className="RESPONSE full-height">
			<Helmet
				titleTemplate={generalText?.siteTitle + ' | %s'}
				defaultTitle={generalText?.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
						
			<div className="SECTION text-center full-height">
				<div className="SECTION__item full-height">
					<div className="SECTION__inner full-height no-padding">
						<div className="SECTION__inner-scroll align-content-vertical">
							<div className="SECTION__inner">
								<div className="grid full-height">
									<div className="row full-height">
										<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3 full-height">
											{
												!props.pageId &&
												<div className="SECTION__icon">
													<div className="icon-info-regular"></div>
												</div>
											}

											{
												!props.pageId &&
												<div className="SECTION__title">Something went wrong</div>
											}

											{
												!props.pageId &&
												<div className="SECTION__subtitle">Please refresh the site</div>
											}

											{
												!props.pageId &&
												<p>If the problem persists, please contact our support team at <a href={
													(process.env?.REACT_APP_NAME === 'FindUpp' ? 'mailto:hello@findupp.com' : 'mailto:hello@tekbruv.com')
												} className="LINK rtl">
													{
														process.env?.REACT_APP_NAME === 'FindUpp' &&
														<>moc.ppudnif@olleh</>
													}
													{/*
														process.env?.REACT_APP_NAME === 'TekBruv' &&
														<>moc.vurbket@olleh</>
													*/}
												</a></p>
											}

											{
												text.icon &&
												<div className="SECTION__icon">
													<div className={text.icon}></div>
												</div>
											}

											{
												text.title &&
												<div className="SECTION__title">{ text.title }</div>
											}

											{
												text.subtitle &&
												<div className="SECTION__subtitle">{ text.subtitle }</div>
											}


											{
												text.text &&
												<div dangerouslySetInnerHTML={{ __html: text.text }}></div>
											}

											{
												text.buttons?.length > 0 &&
												<div className="col-12">
													<div className="SECTION__item">
														<div className="row">
															{
																_.map(text.buttons, (val1, i1) => {
																	return (
																		<div key={i1} className={val1.gridClasses}>
																			{
																				val1.isAcceptCookies &&
																				<span onClick={() => onAcceptCookies()}className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</span>
																			}

																			{
																				val1.isDeclineCookies &&
																				<span onClick={() => onDeclineCookies()}className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</span>
																			}

																			{
																				!val1.isAcceptCookies && !val1.isDeclineCookies &&
																				<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</Link>
																			}
																		</div>
																	)
																})
															}
														</div>
													</div>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Response;