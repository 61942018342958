export const setCookie = (name: string, value: string, days: number) => {
	// Set cookie for days
	let date = new Date();
	date.setTime(+ date + (days * 86400000));

	document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + '; path=/';
}

export const getCookieValue = (name: any) => {
	let cookie: any = {};

	document.cookie.split(';').forEach((el) => {
		let [k,v] = el.split('=');
		cookie[k.trim()] = v;
	});

	return cookie[name];
}

export const checkCookie = (name: string) => {
	if (document.cookie.indexOf(`${name}=`) > -1) {  // Ensure it checks for the cookie name and equals sign
		return true;
	} else {
		return false;
	}
};

export const deleteCookie = (name: string) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}