import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Controller, Scene } from 'react-scrollmagic';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';
import { checkCookie } from './../../other/helpers/cookies';

// Import components
import Loader from './../../components/loader/loader';
import ProgressiveImg from './../../components/progressive-img/progressive-img';
import Popup from './../../components/popup/popup';
import SignIn from './../../components/sign-in/sign-in';
import SignUp from './../../components/sign-up/sign-up';
import RemindPassword from './../../components/remind-password/remind-password';

// Import styles
import './auth.scss';

interface props {
	'pageId': string
}

const Auth = (props: props) => {
	const navigate = useNavigate();

	// const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initPopups = {
		'showRemindPasswordPopup': false
	}
	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
		}).catch((err) => {
			
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.pageId, lang]);

	return (
		<div className="INDEX full-height">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>

			<Loader active={isLoading} fixed={true} />

			<Popup
				title={generalText.remindPasswordPopup?.title}
				active={popups.showRemindPasswordPopup}
				onClose={() => setPopups(initPopups)}
			>
				<RemindPassword
					pageId={props.pageId}
					notifications={(val: {[key: string]: any}) => setNotifications(val)}
					showRemindPasswordPopup={(val: boolean) => setPopups({...initPopups, 'showRemindPasswordPopup': val})}
					isLoading={(val: boolean) => setIsLoading(val)}
				/>
			</Popup>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				closeBtn={generalText?.generalPopup?.button1?.text}
				text={notifications?.text || ['Unhandled error - #12513524524']}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<div className="SECTION full-height text-left">
				<div className="SECTION__bg">
					<div className="SECTION__bg4" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg4.svg)' }}></div>
					<div className="SECTION__bg3" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg3.svg)' }}></div>
				</div>

				<div className="grid full-height">
					<div className={'row full-height' + (text.intro?.position === 'right' ? ' reversed-cols' : '')}>
						<div className={'col-12' + (text.intro?.position === 'right' ? ' push-md-1' : '') + (text.intro?.position !== 'center' ? ' col-sm-6' : '')}>
							{
								text.intro?.image1 &&
								<Controller globalSceneOptions={{
									'triggerHook': 0.95
								}}>
									<Scene>
										{(progress: any, event: any) => (
											<div className={'AUTH__image' + (text.intro?.position === 'right' ? ' right': ' left')}>
												<ProgressiveImg
													img={false}
													showLoader={true}
													shouldLoad={event.type === 'start'}
													lowQualitySrc=""
													highQualitySrc={process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/el/' + (checkCookie('siteWebpSupported') ? text.intro?.image1.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : text.intro?.image1)}
												/>
											</div>
										)}
									</Scene>
								</Controller>
							}

							{
								text.intro?.preTitle &&
								<div className="SECTION__pretitle text-left">
									<span>{ text.intro?.preTitle }</span>
								</div>
							}
							
							{
								text.intro?.title &&
								<h1 className="SECTION__title text-left diff1" dangerouslySetInnerHTML={{ __html: text.intro?.title }}></h1>
							}

							{
								text.intro?.subtitle &&
								<h2 className="SECTION__subtitle text-left" dangerouslySetInnerHTML={{ __html: text.intro?.subtitle }}></h2>
							}
						</div>
						
						<div className={'col-12 full-height' + (text.intro?.position === 'left' ? ' push-md-1' : '') + (text.intro?.position !== 'center' ? ' col-sm-6 col-md-5' : '')}>
							<div className="SECTION__item full-height">
								<div className="SECTION__inner bg-diff1 full-height no-padding">
									<div className="SECTION__inner-scroll align-content-vertical">
										<div className="SECTION__inner">
											{
												props.pageId === 'sign-in' && 
												generalText.signIn?.title &&
												<SignIn
													pageId={props.pageId}
													notifications={(val: {[key: string]: any}) => setNotifications(val)}
													isLoading={(val: boolean) => setIsLoading(val)}
													showRemindPasswordPopup={(val: boolean) => setPopups({...initPopups, 'showRemindPasswordPopup': val})}
												/>
											}

											{
												props.pageId === 'sign-up' && 
												generalText.signUp?.title &&
												<SignUp
													pageId={props.pageId}
													notifications={(val: {[key: string]: any}) => setNotifications(val)}
													isLoading={(val: boolean) => setIsLoading(val)}
												/>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Auth;