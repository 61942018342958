import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import styles
import './chat-widget.scss';

let TawkMessengerReact = require('@tawk.to/tawk-messenger-react');

interface props {};

const ChatWidget = (props: props) => {
	const location = useLocation();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);

	const tawkMessengerRef: any = useRef();

	const [isChatWidgetLoaded, setIsChatWidgetLoaded] = useState<boolean>(false);

	useEffect(() => {
		onChatWidgetLoad();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isChatWidgetLoaded]);

	useEffect(() => {
		showHideWidget();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.isAuthenticated, location.pathname]);

	const onChatWidgetLoad = () => {
		if (!isChatWidgetLoaded) { return; }

		// hide widget before re-styling
		tawkMessengerRef.current.minimize();
		tawkMessengerRef.current.hideWidget();

		// Reset tawk.to iframe styling
		setTimeout(() => {
			let widget = document.getElementsByClassName('widget-hidden')[0];
			let iframe1 = widget.getElementsByTagName('iframe')[0];

			// @ts-ignore
			iframe1.style.bottom = null;
			// @ts-ignore
			iframe1.style.right = null;

			showHideWidget();
		}, 1000);
	}

	const showHideWidget = () => {
		if (!isChatWidgetLoaded) { return; } 

		const isAllowedChatPage: any = (
			location.pathname === slugByPageId('sign-in', lang, routes) ||
			location.pathname === slugByPageId('sign-up', lang, routes) ||
			location.pathname === slugByPageId('about1', lang, routes) ||
			location.pathname === slugByPageId('faq', lang, routes)
		)

		// if (!auth.isAuthenticated) {
		// 	tawkMessengerRef.current.showWidget();
		// } else if (auth.isAuthenticated && isAllowedChatPage) {
		// 	tawkMessengerRef.current.showWidget();
		// } else {
		// 	tawkMessengerRef.current.hideWidget();
		// }

		if (isAllowedChatPage) {
			tawkMessengerRef.current.showWidget();
		} else {
			tawkMessengerRef.current.hideWidget();
		}
	}

	if (process.env?.REACT_APP_ENV === 'development') { return null; }

	let propertyId: string = '';
	let widgetId: string = '';

	if (process.env?.REACT_APP_NAME === 'FindUpp') {
		propertyId = '625d867c7b967b11798b43ee';
		widgetId = '1g0ulambp';
	}

	// if (process.env?.REACT_APP_NAME === 'TekBruv') {
	// 	propertyId = '66d6035950c10f7a00a32ca2'
	// 	widgetId = '1i6pvq5el'
	// }

	return (
		<TawkMessengerReact
			propertyId={propertyId}
			widgetId={widgetId}
			customStyle={{
				'zIndex': 10,
				'visibility': {
					'mobile': {
						'yOffset': 80
					}
				}
			}}
			ref={tawkMessengerRef}
			onLoad={() => setIsChatWidgetLoaded(true)}
		/>
	);
}

export default ChatWidget;
