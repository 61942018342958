import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Navigation, EffectCoverflow, Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller } from 'react-scrollmagic';
import parse from 'html-react-parser';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setGeneral } from './../../other/actions/general';

// Import helpers
// import { validateFields } from './../../other/helpers/validate-fields';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Popup from './../../components/popup/popup';
import Guide from './../../components/guide/guide';
import Loader from './../../components/loader/loader';
// import Textarea from './../../components/textarea/textarea';
// import PostComposeItem from './../../components/post-compose-item/post-compose-item';
import FilterItem from './../../components/filter-item/filter-item';
import ProfileItem from './../../components/profile-item/profile-item';
// import PostItem from './../../components/post-item/post-item';

// Import styles
import './profiles-feed.scss';

interface props {
	'pageId': string
}

const ProfilesFeed = (props: props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const filter = useSelector((state: {[key: string]: any}) => state.filter);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const general = useSelector((state: {[key: string]: any}) => state.general);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	// const initPopups: {[key: string]: any} = {
	// 	'postToReportId': '',
	// 	'postToRemoveId': ''
	// }
	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};
	// const initReport: {[key: string]: any} = {'report': ''};

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});

	const [filteredProfilesAndPosts, setFilteredProfilesAndPosts] = useState<Array<any>>([]);
	const [filteredProfilesAndPostsTotal, setFilteredProfilesAndPostsTotal] = useState<number>(0);
	const [filteredProfilesAndPostsPage, setFilteredProfilesAndPostsPage] = useState<number>(1);
	const [filteredProfilesAndPostsFromDate, setFilteredProfilesAndPostsFromDate] = useState<string>('');

	const [sliderActiveIndex, setSliderActiveIndex] = useState<number>(0);
	const [clickedUrlCheck, setClickedUrlCheck] = useState<{[key: string]: any}>({});
	// const [report, setReport] = useState<{[key: string]: any}>(initReport);
	// const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);
	// const [errors, setErrors] = useState<{[key: string]: any}>({});

	// useEffect(() => {
	// 	if (!_.isEmpty(errors)) {
	// 		setErrors({});
	// 	}
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [report]);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);

			onGetFilteredProfilesAndPosts(1, true, filter);

		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.pageId, lang]);

	const onGetFilteredProfilesAndPosts = async (page: number, hasLoader: boolean, filter: any) => {
		if (hasLoader) {
			setIsLoading(true);
		}

		if (page === 1) {
			setFilteredProfilesAndPosts([]);
			setFilteredProfilesAndPostsPage(1);
			setFilteredProfilesAndPostsTotal(0);
			setFilteredProfilesAndPostsFromDate(new Date().toString());
			setSliderActiveIndex(0);
		}

		const filteredProfilesData: any = await onGetFilteredProfiles(page, hasLoader, filter);
		// const filteredPostsData: any = await onGetFilteredPosts(page, hasLoader, filter);
		const filteredPostsData: any = [];

		if (filteredProfilesData === undefined || filteredPostsData === undefined) {
			setIsLoading(false);
			return;
		}

		if (!_.isEmpty(filteredProfilesData) || !_.isEmpty(filteredPostsData)) {
			setFilteredProfilesAndPostsPage(prevState => (prevState + 1));
		}

		const combinedArray = [...filteredProfilesData, ...filteredPostsData];

		let filteredProfilesAndPostsData = _.chain(combinedArray).map((res: any) => {
			const date = res.sortVar || res.createdAt;
			return { ...res, date };
		}).orderBy(['date'], ['desc']).value();
		
		setFilteredProfilesAndPosts(prevState => [...prevState, ...filteredProfilesAndPostsData]);

		if (hasLoader) {
			setIsLoading(false);
		}
	}

	const onGetFilteredProfiles = async (page: number, hasLoader: boolean, filter: any) => {
		if (!auth.isAuthenticated && page > 4) {
			setNotifications(generalText.messages?.userNotAuthorizedError);
			return;
		}

		return await new Promise((resolve, reject) => {
			if (filter.postKind) {
				resolve([]);
				return;
			}

			api.get('/get-filtered-profiles/' + (filteredProfilesAndPostsFromDate || 'null') + '/' + page).then(async (res) => {
				if (page === 1) {
					setFilteredProfilesAndPostsTotal(prevState => (
						prevState + (res.data?.total || 0)
					));
				}

				resolve(res.data.entries);

			}).catch((err) => {
				setNotifications(err.response?.data?.messages);
				resolve([]);
			});
		});
	};

	// const onGetFilteredPosts = async (page: number, hasLoader: boolean, filter: any) => {
	// 	if (!auth.isAuthenticated && page > 1) {
	// 		setNotifications(generalText.messages?.userNotAuthorizedError);
	// 		return;
	// 	}

	// 	return await new Promise((resolve, reject) => {
	// 		api.get('/get-filtered-posts/' + (page === 1 ? 'null' : filteredProfilesAndPostsFromDate) + '/' + page).then((res) => {

	// 			if (page === 1) {
	// 				setFilteredProfilesAndPostsTotal(prevState => (
	// 					prevState + (res.data?.total || 0)
	// 				));
	// 			}

	// 			resolve(res.data.entries);

	// 		}).catch((err) => {
	// 			setNotifications(err.response?.data?.messages);
	// 			resolve([]);
	// 		});
	// 	});
	// }

	// const onToggleLikeDislike = async (postId: string, isLiked: boolean) => {
	// 	if (!auth.isAuthenticated) {
	// 		setNotifications(generalText.messages?.userNotAuthorizedError);
	// 		return;
	// 	}

	// 	// Check if user has selected his main profile
	// 	if (!auth.mainProfile?.slug) {
	// 		setNotifications(generalText.messages?.profileIsNotSelected);

	// 		return;
	// 	}

	// 	let likeData: any = {
	// 		'isLiked': isLiked,
	// 		'postId': postId,
	// 		'profileSlug': auth.mainProfile?.slug
	// 	}

	// 	// Add or remove like / dislike 
	// 	await new Promise((resolve, reject) => {
	// 		api.post('/toggle-like-dislike', likeData).then((res1) => {
	// 			resolve(true);
				
	// 		}).catch((err) => {
	// 			setNotifications(err.response?.data?.messages);
	// 			reject(false);
	// 		});
	// 	});

	// 	onGetPost(postId);
	// }

	// const onGetPost = (postId: string) => {
	// 	api.get('/get-post/' + postId).then((res1) => {
				
	// 		// Replaced post with an updated version
	// 		onPostUpdate(res1.data[0]);
	// 		setIsLoading(false);

	// 	}).catch((err) => {
	// 		setIsLoading(false);

	// 		setNotifications(err.response?.data?.messages);
	// 	});
	// }

	// const onPostUpdate = (updatedPost: {[key: string]: any}) => {
	// 	// Replaced post with an updated version
	// 	let stateClone = _.cloneDeep(filteredProfilesAndPosts);
	// 	let index = _.findIndex(stateClone, {'_id': updatedPost._id});

	// 	stateClone[index] = updatedPost;

	// 	setFilteredProfilesAndPosts(stateClone);
	// }

	// const onPostRemove = () => {
	// 	setIsLoading(true);

	// 	let postData: any = {
	// 		'id': popups.postToRemoveId
	// 	}
		
	// 	// Remove post
	// 	api.put('/remove-post', postData).then((res) => {

	// 		setPopups(initPopups);
	// 		onGetFilteredProfilesAndPosts(1, true, filter);

	// 	}).catch((err) => {

	// 		setIsLoading(false);
	// 		setPopups(initPopups);
	// 		setNotifications(err.response?.data?.messages);
		
	// 	});
	// }

	// const onPostReport = () => {
	// 	setIsLoading(true);

	// 	let stateClone: any = _.cloneDeep(report);

	// 	// Validation
	// 	let fieldErrors = validateFields(stateClone, ['report']);

	// 	if (!_.isEmpty(fieldErrors)) {
	// 		setErrors(fieldErrors);
	// 		setIsLoading(false);

	// 		return;
	// 	}

	// 	let reportData: any = {
	// 		'id': popups.postToReportId,
	// 		'type': 'Post',
	// 		'report': report.report
	// 	}

	// 	// Report post
	// 	api.post('/add-report', reportData).then((res) => {

	// 		setPopups(initPopups);
	// 		setReport(initReport);
	// 		setNotifications(generalText.messages?.itemReportedMessage);
	// 		setIsLoading(false);

	// 	}).catch((err) => {

	// 		setReport(initReport);
	// 		setPopups(initPopups);
	// 		setNotifications(err.response?.data?.messages);
	// 		setIsLoading(false);

	// 	});
	// }

	const onOpenExternalLink = () => {
		onAddActionStats(clickedUrlCheck.slug, {'type': 'click', 'value': clickedUrlCheck.url});

		let url = clickedUrlCheck.url;

		if (!url.match(/^https?:\/\//i)) {
			url = 'https://' + url;
		}

		setClickedUrlCheck({});

		window.open(url, '_blank');
	}

	const onAddActionStats = (slug: string, data: {[key: string]: any}) => {
		let actionStatsData: object = {
			'slug': slug,
			...data
		}

		api.post('add-action', actionStatsData);
	}

	if (_.isEmpty(text)) { return null; }

	return (
		<div className="PROFILESFEED full-height">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
			
			<Loader active={isLoading} fixed={true} />

			<Guide
				title={text.guidePopup?.title}
				text={text.guidePopup?.text1}
				nextButton={text.guidePopup?.button1}
			/>

			{/*<Popup
				title={text.reportPostPopup?.title}
				submitBtn={text.reportPostPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.postToReportId !== ''}
				onSubmit={() => onPostReport()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="SECTION__item">
									<div className={'FIELD' + (errors.report ? ' error' : '')}>
										<Textarea
											value={report.report}
											placeholder={text.reportPostPopup?.input1?.placeholder}
											label={text.reportPostPopup?.input1?.label}
											onChange={(val: string) => setReport(prevState => ({...prevState, 'report': val}))}
										/>

										{
											errors.report &&
											<p className="error">
												{ errors.report }
											</p>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>*/}

			{/*<Popup
				title={text.removePostPopup?.title}
				text={text.removePostPopup?.text1}
				submitBtn={text.removePostPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.postToRemoveId !== ''}
				onSubmit={() => onPostRemove()}
				onClose={() => setPopups(initPopups)}
			/>*/}

			<Popup
				title={generalText.externalLinkPopup?.title}
				closeBtn={generalText?.generalPopup?.button1?.text}
				submitBtn={generalText.externalLinkPopup?.button1?.text}
				active={!_.isEmpty(clickedUrlCheck)}
				onSubmit={() => onOpenExternalLink()}
				onClose={() => setClickedUrlCheck({})}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								generalText.externalLinkPopup?.text1 &&
								<div className="col-12">
									{
										parse(generalText.externalLinkPopup?.text1, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <strong>{ clickedUrlCheck.url }</strong>;
												}
											}
										})
									}
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #346346235135']}
				active={!_.isEmpty(notifications?.text)}
				closeBtn={generalText?.generalPopup?.button1?.text}
				onClose={() => setNotifications(initNotifications)}
			/>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				{/*<PostComposeItem
					text={text.composePostPopup}
					onGetPosts={() => onGetFilteredProfilesAndPosts(1, true, filter)}
					showNewPostPopup={general.showNewPostPopup}
					onShowNewPostPopup={(val: any) => dispatch(setGeneral({...general, 'showNewPostPopup': val}))}
				/>*/}

				<FilterItem
					filter={filter}
					isSmall={true}
					pageId={props.pageId}
					showFilterPopup={general.showFilterPopup}
					onShowFilterPopup={(val: boolean) => dispatch(setGeneral({...general, 'showFilterPopup': val}))}
					onFilterChange={(val: any) => onGetFilteredProfilesAndPosts(1, true, val)}
				/>

				{
					filteredProfilesAndPosts?.length > 0 &&
					<div className="SECTION PROFILESFEED__slider overflow-hidden full-height">
						<div className="grid full-height">
							<div className="row full-height">
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3 full-height">
									<Swiper
										speed={300}
										className="full-height"
										modules={[Navigation, EffectCoverflow, Virtual]}
										effect="coverflow"
										coverflowEffect={{
											'slideShadows': false,
											// 'depth': 100,
											// 'modifier': 1,
											// 'scale': 1,
											// 'stretch': 0
										}}
										slidesPerView={1}
										loop={false}
										spaceBetween={10}
										autoplay={false}
										onSlideChange={(swiper: any) => {
											const { activeIndex } = swiper;

											setSliderActiveIndex(activeIndex);

											if (filteredProfilesAndPosts.length - activeIndex === 4) {
												onGetFilteredProfilesAndPosts(filteredProfilesAndPostsPage, false, filter)
											}
										}}
										navigation={{
											'prevEl': '.PROFILESFEED__slider .SLIDER__arrowsbig-item.prev',
											'nextEl': '.PROFILESFEED__slider .SLIDER__arrowsbig-item.next',
										}}
										breakpoints={{
											768: {
												'slidesPerView': 1,
												'spaceBetween': 20,
											}
										}}
										virtual={{
											'enabled': true,
											'addSlidesBefore': 2,
											'addSlidesAfter': 2
										}}
									>
										{
											_.map(filteredProfilesAndPosts, (val1, i1) => {
												return (
													<SwiperSlide key={i1} className="SECTION__item full-height">
														<div className={'SECTION__inner no-padding full-height' + (val1.profileId ? '' : ' bg-diff1')}>
															<div className={'SECTION__inner-scroll has-button-space' + (val1.profileId ? ' align-content-vertical post-item button-space-diff1' : ' profile-item')}>
																
																{
																	!val1.profileId &&
																	<ProfileItem
																		isVisible={true}
																		userId={val1.userId}
																		profileId={val1._id}
																		name={val1.name}
																		slug={val1.slug}
																		covers={val1.covers}
																		images={val1.images}
																		types={val1.types}
																		category={val1.category}
																		country={val1.country}
																		city={val1.city}
																		about={val1.about}
																		interests={val1.interests}
																		skills={val1.skills}
																		networks={val1.networks}
																		raisedAt={val1.raisedAt}
																		valueRating={val1.valueRating}
																		activeAt={val1.activeAt}
																		clickedUrlCheck={(val: {[key: string]: any}) => setClickedUrlCheck(val)}
																		setNotifications={(val: {[key: string]: any}) => setNotifications(val)}
																	/>
																}

																{/*{
																	val1.profileId &&
																	<PostItem
																		postId={val1._id}
																		userId={val1.userId}
																		profileId={val1.profileId}
																		isProfileVisible={val1.profile?.isVisible}
																		profileStatus={val1.profile?.status}
																		profileSlug={val1.profile?.slug}
																		name={val1.profile?.name}
																		images={val1.profile?.images}
																		profileNetworks={val1.profile?.networks}
																		text={val1.text?.[lang]}
																		country={val1.country}
																		city={val1.city}
																		type={val1.type}
																		category={val1.category}
																		interest={val1.interest}
																		likes={val1.likes}
																		dislikes={val1.dislikes}
																		isLiked={val1.isLiked}
																		isDisliked={val1.isDisliked}
																		comments={val1.comments}
																		createdAt={val1.createdAt}
																		status={val1.status}
																		statusMessage={val1.statusMessage}
																		kind={val1.kind}
																		slug={val1.slug?.[lang]}
																		clickedUrlCheck={(val: {[key: string]: any}) => setClickedUrlCheck(val)}
																		postToReportId={(val: string) => setPopups({...initPopups, 'postToReportId': val})}
																		postToRemoveId={(val: string) => setPopups({...initPopups, 'postToRemoveId': val})}
																		onViewStatusMessage={(val: string) => setNotifications({'title': text.profilePosts?.rejectionTitle, 'text': [val]})}
																		onToggleLikeDislike={(postId: string, isLiked: boolean) => onToggleLikeDislike(postId, isLiked)}
																	/>
																}*/}
															</div>
														</div>
													</SwiperSlide>

												)
											})
										}

										{
											filteredProfilesAndPosts?.length > 0 && 
											(filteredProfilesAndPostsTotal > filteredProfilesAndPosts.length) && 
											text.profiles?.itemsEnd?.title &&
											<SwiperSlide className="SECTION__item full-height">
												<div className="SECTION__inner bg-diff1 no-padding full-height text-center">
													<div className="SECTION__inner-scroll has-button-space align-content-vertical">
														<div>
															<div className="SECTION__inner">
																{
																	text.profiles?.itemsEnd?.icon &&
																	<div className="SECTION__icon">
																		<div className={text.profiles?.itemsEnd?.icon}></div>
																	</div>
																}

																{
																	text.profiles?.itemsEnd?.title &&
																	<div className="SECTION__title">{ text.profiles?.itemsEnd?.title }</div>
																}
																
																{
																	text.profiles?.itemsEnd?.subtitle &&
																	<div className="SECTION__subtitle">{ text.profiles?.itemsEnd?.subtitle }</div>
																}
																
																{
																	text.profiles?.itemsEnd?.text &&
																	<div dangerouslySetInnerHTML={{ __html: text.profiles?.itemsEnd?.text }}></div>
																}

																{
																	text.profiles?.itemsEnd?.buttons?.length > 0 &&
																	<div className="SECTION__item">
																		<div className="row">
																			{
																				_.map(text.profiles?.itemsEnd?.buttons, (val1, i1) => {
																					return (
																						<div key={i1} className={val1.gridClasses}>
																							<button onClick={() => onGetFilteredProfilesAndPosts(filteredProfilesAndPostsPage, true, filter)} className={'BUTTON' + (val1.link ? ' ' + val1.link : '')}>{ val1.text }</button>		
																						</div>
																					)
																				})
																			}
																		</div>
																	</div>
																}
															</div>
														</div>
													</div>
												</div>
											</SwiperSlide>
										}

										{
											filteredProfilesAndPosts.length !== 0 &&
											filteredProfilesAndPostsTotal <= filteredProfilesAndPosts.length && 
											text.profiles?.outro?.title &&
											<SwiperSlide className="SECTION__item full-height">
												<div className="SECTION__inner bg-diff1 no-padding full-height text-center">
													<div className="SECTION__inner-scroll has-button-space align-content-vertical">
														<div>
															<div className="SECTION__inner">
																{
																	text.profiles?.outro?.icon &&
																	<div className="SECTION__icon">
																		<div className={text.profiles?.outro?.icon}></div>
																	</div>
																}

																{
																	text.profiles?.outro?.title &&
																	<div className="SECTION__title">{ text.profiles?.outro?.title }</div>
																}
																
																{
																	text.profiles?.outro?.subtitle &&
																	<div className="SECTION__subtitle">{ text.profiles?.outro?.subtitle }</div>
																}
																
																{
																	text.profiles?.outro?.text &&
																	<div dangerouslySetInnerHTML={{ __html: text.profiles?.outro?.text }}></div>
																}

																{
																	text.profiles?.outro?.buttons?.length > 0 &&
																	<div className="SECTION__item">
																		<div className="row">
																			{
																				_.map(text.profiles?.outro?.buttons, (val1, i1) => {
																					return (
																						<div key={i1} className={val1.gridClasses}>	
																							{
																								val1.showFilterPopup &&
																								<button onClick={() => dispatch(setGeneral({...general, 'showFilterPopup': true}))} className="BUTTON">{ val1.text }</button>
																							}

																							{
																								!val1.showFilterPopup &&
																								<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.link ? ' ' + val1.link : '')}>{ val1.text }</Link>
																							}
																						</div>
																					)
																				})
																			}
																		</div>
																	</div>
																}
															</div>
														</div>
													</div>
												</div>
											</SwiperSlide>
										}

										<div className="SLIDER__arrowsbig size1">
											<div className="grid">
												<div className="row">
													<div className="col-4">
														<div className="SLIDER__arrowsbig-item prev">
															<span className={generalText.other?.sliderArrowsBig}></span>
														</div>
													</div>

													<div className="col-4">
														{
															filteredProfilesAndPosts[sliderActiveIndex] &&
															<Link to={slugByPageId('profile', lang, routes).replace(':slug', (
																filteredProfilesAndPosts?.[sliderActiveIndex]?.profile ? 
																	filteredProfilesAndPosts?.[sliderActiveIndex]?.profile?.slug : 
																	filteredProfilesAndPosts?.[sliderActiveIndex]?.slug
															))} className="SLIDER__arrowsbig-item cta">
																<span className={generalText.profileItem?.button5?.icon}></span>
															</Link>
														}
													</div>

													<div className="col-4">
														<div className="SLIDER__arrowsbig-item next">
															<span className={generalText.other?.sliderArrowsBig}></span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Swiper>
								</div>
							</div>
						</div>
					</div>
				}

				{
					auth.isAuthenticated &&
					(!isLoading && filteredProfilesAndPosts?.length === 0) && 
					text.profiles?.noResults?.title &&
					<div className="SECTION text-center full-height">
						<div className="grid full-height">
							<div className="row full-height">
								<div className="col-12 full-height">
									<div className="SECTION__inner full-height no-padding">
										<div className="SECTION__inner-scroll align-content-vertical">
											<div className="SECTION__inner">
												{
													text.profiles?.noResults?.icon &&
													<div className="SECTION__icon">
														<div className={text.profiles?.noResults?.icon}></div>
													</div>
												}
												
												{
													text.profiles?.noResults?.title &&
													<div className="SECTION__title">{ text.profiles?.noResults?.title }</div>
												}
												
												{
													text.profiles?.noResults?.subtitle &&
													<div className="SECTION__subtitle">{ text.profiles?.noResults?.subtitle }</div>
												}
												
												{
													text.profiles?.noResults?.text &&
													<div dangerouslySetInnerHTML={{ __html: text.profiles?.noResults?.text }}></div>
												}

												{
													text.profiles?.noResults?.buttons?.length > 0 &&
													<div className="SECTION__item">
														<div className="row">
															{
																_.map(text.profiles?.noResults?.buttons, (val1, i1) => {
																	return (
																		<div key={i1} className={val1.gridClasses}>
																			{
																				val1.link &&
																				<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</Link>
																			}

																			{
																				!val1.link &&
																				val1.showNewPostPopup &&
																				<div onClick={() => dispatch(setGeneral({...general, 'showNewPostPopup': !general.showNewPostPopup}))} className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</div>
																			}

																			{
																				!val1.link &&
																				val1.showFilterPopup &&
																				<div onClick={() => dispatch(setGeneral({...general, 'showFilterPopup': !general.showFilterPopup}))} className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</div>
																			}
																		</div>
																	)
																})
															}
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</Controller>
		</div>
	);
}

export default ProfilesFeed;